import { useEffect } from 'react';

export default function useLockBodyScroll(lock: boolean = true): void {
    useEffect(() => {
        document.body.style.overflow = lock ? 'hidden' : 'visible';
        window.scrollTo(0, 0);

        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [lock]);
}
